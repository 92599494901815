var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pa-3", attrs: { align: "center", justify: "center" } },
    [
      _c("Loading", {
        attrs: { active: _vm.loadingInicial, "is-full-page": true }
      }),
      _c(
        "v-card",
        { attrs: { align: "left", justify: "left" } },
        [
          _c(
            "v-card-text",
            { staticClass: "pa-3 font-weight-light white black--text" },
            [
              _c("v-icon", { staticClass: "primary--text lighten-2 pa-2" }, [
                _vm._v(" mdi-poll")
              ]),
              _vm._v("Mensagens > Relatórios de envio de E-mails ")
            ],
            1
          )
        ],
        1
      ),
      _c("v-spacer"),
      _c(
        "v-card",
        { staticClass: "my-6" },
        [
          _c("v-row", [
            _c(
              "div",
              {
                staticClass: "ma-4 pa-2 mx-auto",
                attrs: { "max-width": "400" }
              },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { sm: "12", lg: "5" } },
                      [
                        _c(
                          "v-sheet",
                          {
                            staticClass: "v-sheet--offset ml-3",
                            attrs: {
                              color: "primary",
                              elevation: "1",
                              "max-width": "100%"
                            }
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "ma-1 pa-1",
                                attrs: { color: "white", size: "400%" }
                              },
                              [_vm._v(" mdi-chart-timeline-variant ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { sm: "12", lg: "7" } },
                      [
                        _c(
                          "v-card-text",
                          { staticClass: "pt-0" },
                          [
                            _c(
                              "div",
                              { staticClass: "font-weight-light grey--text" },
                              [_vm._v(" Total ")]
                            ),
                            _c(
                              "v-card-text",
                              {
                                staticClass:
                                  "font-weight-bold grey--text display-1 text-no-wrap"
                              },
                              [
                                _vm.carregandoTotal
                                  ? _c("v-progress-circular", {
                                      attrs: {
                                        color: "primary",
                                        small: "",
                                        indeterminate: ""
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" " + _vm._s(_vm.resultadoTotal) + " ")
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-col",
                  [
                    _c(
                      "div",
                      {
                        staticClass: "subheading font-weight-light grey--text"
                      },
                      [_vm._v(" Número de envios na Base ")]
                    ),
                    _c("v-divider", { staticClass: "my-2" }),
                    _c(
                      "v-icon",
                      { staticClass: "mr-2", attrs: { small: "" } },
                      [_vm._v(" mdi-clock ")]
                    ),
                    _c(
                      "span",
                      { staticClass: "caption grey--text font-weight-light" },
                      [_vm._v("Atualizado desde o último acesso")]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "ma-4 pa-2 mx-auto",
                attrs: { "max-width": "400" }
              },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { sm: "12", lg: "5" } },
                      [
                        _c(
                          "v-sheet",
                          {
                            staticClass: "v-sheet--offset ml-3",
                            attrs: {
                              color: "primary",
                              elevation: "1",
                              "max-width": "100%"
                            }
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "ma-1 pa-1",
                                attrs: { color: "white", size: "400%" }
                              },
                              [_vm._v(" mdi-chart-timeline-variant ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { sm: "12", lg: "7" } },
                      [
                        _c(
                          "v-card-text",
                          { staticClass: "pt-0" },
                          [
                            _c(
                              "div",
                              { staticClass: "font-weight-light grey--text" },
                              [_vm._v(" Últimos 30 dias ")]
                            ),
                            _c(
                              "v-card-text",
                              {
                                staticClass:
                                  "font-weight-bold grey--text display-1 text-no-wrap"
                              },
                              [
                                _vm.carregando30Dias
                                  ? _c("v-progress-circular", {
                                      attrs: {
                                        color: "primary",
                                        small: "",
                                        indeterminate: ""
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" " + _vm._s(_vm.resultado30Dias) + " ")
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-col",
                  [
                    _c(
                      "div",
                      {
                        staticClass: "subheading font-weight-light grey--text"
                      },
                      [_vm._v(" Número de envios na Base ")]
                    ),
                    _c("v-divider", { staticClass: "my-2" }),
                    _c(
                      "v-icon",
                      { staticClass: "mr-2", attrs: { small: "" } },
                      [_vm._v(" mdi-clock ")]
                    ),
                    _c(
                      "span",
                      { staticClass: "caption grey--text font-weight-light" },
                      [_vm._v("Atualizado desde o último acesso")]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "ma-4 pa-2 mx-auto",
                attrs: { "max-width": "400" }
              },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { sm: "12", lg: "5" } },
                      [
                        _c(
                          "v-sheet",
                          {
                            staticClass: "v-sheet--offset ml-3",
                            attrs: {
                              color: "primary",
                              elevation: "1",
                              "max-width": "100%"
                            }
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "ma-1 pa-1",
                                attrs: { color: "white", size: "400%" }
                              },
                              [_vm._v(" mdi-chart-timeline-variant ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { sm: "12", lg: "7" } },
                      [
                        _c(
                          "v-card-text",
                          { staticClass: "pt-0" },
                          [
                            _c(
                              "div",
                              { staticClass: "font-weight-light grey--text" },
                              [_vm._v(" Últimos 60 dias ")]
                            ),
                            _c(
                              "v-card-text",
                              {
                                staticClass:
                                  "font-weight-bold grey--text display-1 text-no-wrap"
                              },
                              [
                                _vm.carregando60Dias
                                  ? _c("v-progress-circular", {
                                      attrs: {
                                        color: "primary",
                                        small: "",
                                        indeterminate: ""
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" " + _vm._s(_vm.resultado60Dias) + " ")
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-col",
                  [
                    _c(
                      "div",
                      {
                        staticClass: "subheading font-weight-light grey--text"
                      },
                      [_vm._v(" Número de envios na Base ")]
                    ),
                    _c("v-divider", { staticClass: "my-2" }),
                    _c(
                      "v-icon",
                      { staticClass: "mr-2", attrs: { small: "" } },
                      [_vm._v(" mdi-clock ")]
                    ),
                    _c(
                      "span",
                      { staticClass: "caption grey--text font-weight-light" },
                      [_vm._v("Atualizado desde o último acesso")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "div",
        { attrs: { align: "center", justify: "center" } },
        [
          _c(
            "v-card",
            {
              staticClass: "pa-5 my-3",
              nativeOn: {
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.botaoListarEnvios.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "v-row",
                {},
                [
                  _c(
                    "v-col",
                    {
                      attrs: { cols: "4", xs: "2", sm: "2", md: "3", lg: "3" }
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          solo: "",
                          small: "",
                          "prepend-icon": "mdi-chevron-right",
                          label: "Código do Título",
                          hint:
                            "Informe o código do título para efetuar a busca",
                          placeholder: "Ex.: 012345"
                        },
                        model: {
                          value: _vm.filtros.ds_nosso_numero,
                          callback: function($$v) {
                            _vm.$set(_vm.filtros, "ds_nosso_numero", $$v)
                          },
                          expression: "filtros.ds_nosso_numero"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: { cols: "4", xs: "2", sm: "2", md: "3", lg: "3" }
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          solo: "",
                          small: "",
                          label: "Nome do Destinatário",
                          hint: "Informe o nome para efetuar a busca",
                          placeholder: "Ex.: Fernando Alves",
                          clearable: ""
                        },
                        model: {
                          value: _vm.filtros.nm_devedor,
                          callback: function($$v) {
                            _vm.$set(_vm.filtros, "nm_devedor", $$v)
                          },
                          expression: "filtros.nm_devedor"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: { cols: "4", xs: "2", sm: "2", md: "3", lg: "3" }
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          solo: "",
                          small: "",
                          label: "E-mail do Destinatário",
                          hint: "Informe o email para efetuar a busca",
                          placeholder: "Ex.: contato@email.com"
                        },
                        model: {
                          value: _vm.filtros.ds_email_devedor,
                          callback: function($$v) {
                            _vm.$set(_vm.filtros, "ds_email_devedor", $$v)
                          },
                          expression: "filtros.ds_email_devedor"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: { cols: "10", xs: "2", sm: "2", md: "3", lg: "3" }
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          solo: "",
                          small: "",
                          items: _vm.listaStatus,
                          "item-text": "descricao",
                          "item-value": "codigo",
                          label: "Status"
                        },
                        model: {
                          value: _vm.filtros.cd_status,
                          callback: function($$v) {
                            _vm.$set(_vm.filtros, "cd_status", $$v)
                          },
                          expression: "filtros.cd_status"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: { cols: "3", xs: "2", sm: "4", md: "3", lg: "3" }
                    },
                    [
                      _c(
                        "v-menu",
                        {
                          ref: "menuDtInicio",
                          attrs: {
                            "close-on-content-click": false,
                            "return-value": _vm.filtros.dt_inicio,
                            transition: "scale-transition",
                            "offset-y": ""
                          },
                          on: {
                            "update:returnValue": function($event) {
                              return _vm.$set(_vm.filtros, "dt_inicio", $event)
                            },
                            "update:return-value": function($event) {
                              return _vm.$set(_vm.filtros, "dt_inicio", $event)
                            }
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function({ on, attrs }) {
                                return [
                                  _c(
                                    "v-text-field",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: {
                                            "data-cy":
                                              "dataMovimentoFilterMensageria",
                                            solo: "",
                                            label:
                                              "Selecione a data inicial e final",
                                            "prepend-icon": "mdi-calendar",
                                            readonly: "",
                                            clearable: ""
                                          },
                                          model: {
                                            value: _vm.filtros.dt_inicio,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.filtros,
                                                "dt_inicio",
                                                $$v
                                              )
                                            },
                                            expression: "filtros.dt_inicio"
                                          }
                                        },
                                        "v-text-field",
                                        attrs,
                                        false
                                      ),
                                      on
                                    )
                                  )
                                ]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.menuDtInicio,
                            callback: function($$v) {
                              _vm.menuDtInicio = $$v
                            },
                            expression: "menuDtInicio"
                          }
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-card-text",
                                {
                                  staticClass: "text-center",
                                  attrs: { "max-width": "200px" }
                                },
                                [
                                  _vm._v(
                                    "Importante: Selecione a data inicial e a data final"
                                  )
                                ]
                              ),
                              _c(
                                "v-date-picker",
                                {
                                  attrs: {
                                    "no-title": "",
                                    range: "",
                                    "selected-items-text": "Datas Selecionadas",
                                    width: "25vw",
                                    scrollable: "",
                                    color: "primary"
                                  },
                                  model: {
                                    value: _vm.filtros.dt_inicio,
                                    callback: function($$v) {
                                      _vm.$set(_vm.filtros, "dt_inicio", $$v)
                                    },
                                    expression: "filtros.dt_inicio"
                                  }
                                },
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "primary" },
                                      on: {
                                        click: function($event) {
                                          _vm.menuDtInicio = false
                                        }
                                      }
                                    },
                                    [_vm._v(" Cancel ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "primary" },
                                      on: {
                                        click: function($event) {
                                          _vm.$refs.menuDtInicio.save(
                                            _vm.filtros.dt_inicio
                                          )
                                          _vm.tratarRangeData()
                                        }
                                      }
                                    },
                                    [_vm._v(" OK ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-right",
                      attrs: { sm: "12", md: "12", lg: "12" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mx-2",
                          attrs: {
                            disabled:
                              _vm.isDisabledExport || _vm.totalTitulos == 0,
                            color: "green white--text",
                            "data-cy": "btnExportarXlsMensageria",
                            loading: _vm.loading
                          },
                          on: { click: _vm.exportarXls }
                        },
                        [
                          _vm._v(" Exportar Xls "),
                          _c("v-icon", { staticClass: "ml-2" }, [
                            _vm._v("mdi-microsoft-excel")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "green white--text",
                            loading: _vm.loading,
                            "data-cy": "btnListarEnvios"
                          },
                          on: { click: _vm.botaoListarEnvios }
                        },
                        [
                          _vm._v(" Buscar Resultados "),
                          _c("v-icon", { staticClass: "ml-2" }, [
                            _vm._v("search")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              headers: _vm.headers,
              items: _vm.listaEnvios,
              options: _vm.options,
              loading: _vm.loading,
              "server-items-length": _vm.totalTitulos,
              "loading-text": "Buscando envios...",
              "item-key": "comarcas",
              "no-data-text":
                "Nenhum resultado encontrado... Faça uma pesquisa",
              "footer-props": {
                itemsPerPageOptions: [20, 100, 200, -1]
              }
            },
            on: {
              "update:options": function($event) {
                _vm.options = $event
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: `item.dt_envio`,
                  fn: function({ item }) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(_vm.formatDate(item.dt_envio)))
                      ])
                    ]
                  }
                },
                {
                  key: `item.dt_abertura`,
                  fn: function({ item }) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(_vm.formatDate(item.dt_abertura)))
                      ])
                    ]
                  }
                },
                {
                  key: `item.dt_clique`,
                  fn: function({ item }) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(_vm.formatDate(item.dt_clique)))
                      ])
                    ]
                  }
                },
                {
                  key: `item.dt_entregue`,
                  fn: function({ item }) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(_vm.formatDate(item.dt_entregue)))
                      ])
                    ]
                  }
                },
                {
                  key: `item.cd_status`,
                  fn: function({ item }) {
                    return [
                      item.cd_status == "102"
                        ? _c("span", [_c("p", [_vm._v("Protestado")])])
                        : _vm._e(),
                      item.cd_status == "225"
                        ? _c("span", [_c("p", [_vm._v("Apontado")])])
                        : _vm._e(),
                      item.cd_status == "241"
                        ? _c("span", [
                            _c("p", [_vm._v("Enviado a Cancelamento")])
                          ])
                        : _vm._e(),
                      item.cd_status == "251"
                        ? _c("span", [_c("p", [_vm._v("Enviado a Anuência")])])
                        : _vm._e(),
                      item.cd_status == "252"
                        ? _c("span", [_c("p", [_vm._v("Anuência Programada")])])
                        : _vm._e()
                    ]
                  }
                },
                {
                  key: `item.st_ativo`,
                  fn: function({ item }) {
                    return [
                      _c(
                        "v-icon",
                        {
                          style: {
                            color: item.st_ativo != "" ? "green" : "red"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.getBolean(item.st_ativo)))]
                      )
                    ]
                  }
                },
                {
                  key: `item.is_admin`,
                  fn: function({ item }) {
                    return [
                      _c(
                        "v-icon",
                        {
                          style: {
                            color: item.is_admin != "" ? "green" : "red"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.getBolean(item.is_admin)))]
                      )
                    ]
                  }
                },
                {
                  key: `item.tracking`,
                  fn: function({ item }) {
                    return [
                      item.tracking == "2"
                        ? _c(
                            "span",
                            { staticClass: "d-flex flex-row" },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "", color: "success" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      elevation: "0",
                                                      "x-small": "",
                                                      fab: ""
                                                    }
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass:
                                                      "ma-2 pa-2 blue--text"
                                                  },
                                                  [_vm._v("mdi-check-all")]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "E-mail visualizado pelo destinatário"
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "", color: "success" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      elevation: "0",
                                                      "x-small": "",
                                                      fab: ""
                                                    }
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { staticClass: "ma-2 pa-2" },
                                                  [
                                                    _vm._v(
                                                      "mdi-cursor-default-click"
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c("span", [
                                    _vm._v("Essa mensagem recebeu cliques")
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      item.tracking == "1"
                        ? _c(
                            "span",
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "", color: "success" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      "x-small": "",
                                                      fab: "",
                                                      elevation: "0"
                                                    }
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass:
                                                      "ma-2 pa-2 blue--text"
                                                  },
                                                  [_vm._v("mdi-check-all")]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "E-mail visualizado pelo destinatário"
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      item.tracking == "0"
                        ? _c(
                            "span",
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "", color: "success" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      "x-small": "",
                                                      fab: "",
                                                      elevation: "0"
                                                    }
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass:
                                                      "ma-2 pa-2 grey--text"
                                                  },
                                                  [_vm._v("mdi-check")]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c("span", [
                                    _vm._v("E-mail Entregue ao destinatário")
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      item.tracking == null
                        ? _c(
                            "span",
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "", color: "grey" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      elevation: "0",
                                                      "x-small": "",
                                                      fab: ""
                                                    }
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { staticClass: "ma-2 pa-2" },
                                                  [
                                                    _vm._v(
                                                      "mdi-dots-horizontal"
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [_c("span", [_vm._v("E-mail enviado")])]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  }
                },
                {
                  key: `item.action2`,
                  fn: function({ item }) {
                    return [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2 grey white--text",
                          style: { color: "primary" },
                          attrs: {
                            small: "",
                            elevation: "1",
                            width: "100%",
                            "full-width": ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.exibirDetalhe(item)
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mr-2",
                              style: { color: "white" },
                              on: {
                                click: function($event) {
                                  return _vm.exibirDetalhe(item)
                                }
                              }
                            },
                            [_vm._v("mdi-account-details")]
                          ),
                          _vm._v(" ver título ")
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      ),
      _vm.snackbarDialog
        ? _c("Snackbar", {
            attrs: {
              mostrarSnackbar: _vm.snackbarDialog,
              corSnackbar: _vm.snackbarColor,
              mensagemSnackbar: _vm.mensagemRetornoSnackbar
            },
            on: {
              fecharSnackbar: function($event) {
                _vm.snackbarDialog = false
              }
            }
          })
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "1200px",
            persistent: "",
            scrollable: "",
            color: "background",
            transition: "dialog-bottom-transition"
          },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "pa-0" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "", dark: "", color: "primary" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-0",
                          attrs: { icon: "", dark: "" },
                          on: { click: _vm.closeDialog }
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      ),
                      _c("v-toolbar-title", [_vm._v("Detalhes do Título")]),
                      _c("v-spacer")
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-text",
                { staticClass: "px-8 py-4" },
                [
                  _c("DetalheTitulo", {
                    attrs: { titulo: _vm.titulo },
                    on: { fecharModal: _vm.closeDialog }
                  }),
                  _c("HistoricoTitulo", {
                    staticClass: "mt-3",
                    attrs: { historico_titulo: _vm.historicos }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.modalEmailXls,
            callback: function($$v) {
              _vm.modalEmailXls = $$v
            },
            expression: "modalEmailXls"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "headline primary--text" },
                    [_vm._v("Relatório via e-mail")]
                  ),
                  _vm.filtros != null
                    ? _c("v-card-text", [
                        _vm._v(
                          " O relatórios será aplicado com os seguintes filtros: "
                        )
                      ])
                    : _vm._e(),
                  _vm.filtros.ds_nosso_numero != null
                    ? _c(
                        "v-card-text",
                        [
                          _c("v-icon", [_vm._v("mdi-information-outline")]),
                          _vm._v(
                            " Número do Título: " +
                              _vm._s(_vm.filtros.ds_nosso_numero) +
                              " "
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.filtros.nm_devedor != null
                    ? _c(
                        "v-card-text",
                        [
                          _c("v-icon", [_vm._v("mdi-information-outline")]),
                          _vm._v(
                            " Nome do Devedor: " +
                              _vm._s(_vm.filtros.nm_devedor) +
                              " "
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.filtros.ds_email_devedor != null
                    ? _c(
                        "v-card-text",
                        [
                          _c("v-icon", [_vm._v("mdi-information-outline")]),
                          _vm._v(
                            " E-mail do Devedor: " +
                              _vm._s(_vm.filtros.ds_email_devedor) +
                              " "
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.filtros.cd_status != null
                    ? _c(
                        "v-card-text",
                        [
                          _c("v-icon", [_vm._v("mdi-information-outline")]),
                          _vm._v(
                            " Código do Status: " +
                              _vm._s(_vm.filtros.cd_status) +
                              " "
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.filtros.dt_inicio != null
                    ? _c(
                        "v-card-text",
                        [
                          _c("v-icon", [_vm._v("mdi-information-outline")]),
                          _vm._v(
                            " Data de início dos envios: " +
                              _vm._s(_vm.filtros.dt_inicio) +
                              " "
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.filtros.dt_fim != null
                    ? _c(
                        "v-card-text",
                        [
                          _c("v-icon", [_vm._v("mdi-information-outline")]),
                          _vm._v(
                            " Data de fim dos envios: " +
                              _vm._s(_vm.filtros.dt_fim) +
                              " "
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("v-spacer"),
                  _c(
                    "v-form",
                    {
                      ref: "formEmail",
                      model: {
                        value: _vm.validEmail,
                        callback: function($$v) {
                          _vm.validEmail = $$v
                        },
                        expression: "validEmail"
                      }
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          "prepend-icon": "person",
                          name: "email",
                          label: "E-mail",
                          type: "email",
                          rules: _vm.emailRules,
                          loading: _vm.loadingFiltros,
                          required: "",
                          "data-cy": "emailReportMensageria"
                        },
                        model: {
                          value: _vm.emailReport,
                          callback: function($$v) {
                            _vm.emailReport = $$v
                          },
                          expression: "emailReport"
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-card-subtitle", [
                    _vm._v("Digite o e-mail onde deseja receber o relatório")
                  ])
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        disabled: _vm.checkForm(),
                        "data-cy": "btnEnviarEmailRelatorioMensageria"
                      },
                      on: {
                        click: function($event) {
                          return _vm.exportarRelatoriosMsgs()
                        }
                      }
                    },
                    [
                      _vm._v(" Enviar "),
                      _c("v-icon", { staticClass: "ml-1" }, [
                        _vm._v("chevron_right")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.modalConfirmacao,
            callback: function($$v) {
              _vm.modalConfirmacao = $$v
            },
            expression: "modalConfirmacao"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "headline primary--text" },
                    [_vm._v("Relatório solicitado com sucesso.")]
                  ),
                  _c("v-spacer"),
                  _c("v-card-subtitle", [
                    _vm._v("O relatório será enviado para o seu e-mail!")
                  ])
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.modalConfirmacao,
                          expression: "modalConfirmacao"
                        }
                      ],
                      attrs: { color: "green darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.modalConfirmacao = false
                        }
                      }
                    },
                    [_vm._v("Ok!")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }